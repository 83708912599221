import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { injectIntl, intlShape } from 'react-intl';
import { PrivacyLink, TermsLink } from '@/components/Links';

const Container = styled.div`
  margin: 48px 0 24px 0;
  padding: 0 16px;
  text-align: center;
`;
const LinkWrapper = styled.div`
  margin-bottom: 8px;
  a {
    color: rgba(0, 0, 0, 0.45);
    transition: all 0.3s;
    &:not(:last-child) {
      margin-right: 40px;
    }
    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
`;
const Copyright = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
`;

@injectIntl
@observer
class Footer extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <Container>
        <LinkWrapper>
          <PrivacyLink>{formatMessage({ id: 'footer.link.privacy' })}</PrivacyLink>
          <TermsLink>{formatMessage({ id: 'footer.link.terms' })}</TermsLink>
        </LinkWrapper>
        <Copyright>{formatMessage({ id: 'footer.copyright' }, { year: new Date().getFullYear() })}</Copyright>
      </Container>
    );
  }
}

export default Footer;

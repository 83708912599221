import React from 'react';
import Loadable from 'react-loadable';
import PageLoading from '@/components/PageLoading';

const LoadableExtract = Loadable({
  loader: () => import('./LoadableExtract'),
  loading: PageLoading,
});

const ExtractFiles = () => <LoadableExtract />;

export default ExtractFiles;

export const MessageType = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const EnvPlatform = {
  seal: 'SEAL',
  dvfcloud: 'DVFCLOUD',
  wxBind: 'WXBIND',
  usEast: 'US_EAST',
  ops: 'OPS',
};

export const isPlatformIncludes = value => {
  const platforms = Array.isArray(value) ? value : [value];
  return platforms.includes(process.env.PLATFORM);
};

import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageLoading = () => (
  <LoadingWrapper>
    <Spin size="large" />
  </LoadingWrapper>
);

export default PageLoading;

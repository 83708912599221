import React from 'react';
import { Router } from 'react-router-dom';
import { Provider as Mobx } from 'mobx-react';
import PropTypes from 'prop-types';
import appStore from '@/models/app';
import history from '../history';
import Locale from './Locale';

const Provider = ({ children }) => (
  <Mobx appStore={appStore}>
    <Locale>
      <Router history={history}>{children}</Router>
    </Locale>
  </Mobx>
);

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Provider;

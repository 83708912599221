import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { observer, inject } from 'mobx-react';
import message from '@/locales';

@inject('appStore')
@observer
class LocaleProvider extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    appStore: PropTypes.shape({
      lang: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { children } = this.props;
    const { lang } = this.props.appStore;
    return (
      <IntlProvider locale={lang} messages={message[lang]}>
        {children}
      </IntlProvider>
    );
  }
}

export default LocaleProvider;

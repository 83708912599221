import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Container = styled.div`
  margin-top: 100px;
`;

const ComponentLoading = () => (
  <Container>
    <Spin size="large" />
  </Container>
);

export default ComponentLoading;

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

export const PrivacyLink = injectIntl(({ intl, children }) => {
  const { locale } = intl;
  return (
    <a href={`https://policies.deepvessel.net/#/privacy?locale=${locale}`} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
});

PrivacyLink.propTypes = {
  intl: intlShape,
  children: PropTypes.string.isRequired,
};

export const TermsLink = injectIntl(({ intl, children }) => {
  const { locale } = intl;
  return (
    <a href={`https://policies.deepvessel.net/#/terms?locale=${locale}`} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
});

TermsLink.propTypes = {
  intl: intlShape,
  children: PropTypes.string.isRequired,
};

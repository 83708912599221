import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Dropdown, Menu, Icon } from 'antd';

const Label = styled.span`
  cursor: default;
  margin: 0 5px;
`;

@inject('appStore')
@observer
class SelectLang extends React.Component {
  static propTypes = {
    appStore: PropTypes.shape({
      lang: PropTypes.string,
      switchLang: PropTypes.func,
    }).isRequired,
  };

  render() {
    const langTable = {
      zh: '中文 (简)',
      'zh-Hant-HK': '中文 (繁)',
      en: 'English',
    };
    const { lang } = this.props.appStore;
    const langMenu = (
      <Menu selectedKeys={[lang]} onClick={this.onChangeLang}>
        {Object.keys(langTable).map(locale => (
          <Menu.Item key={locale}>{langTable[locale]}</Menu.Item>
        ))}
      </Menu>
    );
    return (
      <Dropdown overlay={langMenu}>
        <span>
          <Icon type="global" />
          <Label>{langTable[lang]}</Label>
          <Icon type="down" />
        </span>
      </Dropdown>
    );
  }

  onChangeLang = ({ key }) => {
    this.props.appStore.switchLang(key);
  };
}

export default SelectLang;

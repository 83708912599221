import React from 'react';
import Loadable from 'react-loadable';
import CommonContainer from '@/components/CommonContainer';
import ComponentLoading from '@/components/ComponentLoading';

const LoadableLogin = Loadable({
  loader: () => import('./LoadableLogin'),
  loading: ComponentLoading,
});

const Login = () => (
  <CommonContainer>
    <LoadableLogin />
  </CommonContainer>
);

export default Login;

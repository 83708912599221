/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';
import SelectLang from '@/components/SelectLang';
import Footer from '@/components/Footer';
import backgroundImage from '@/assets/images/login_background.jpg';
import logoImage from '@/assets/images/logo.png';
import dvfcloudImage from '@/assets/images/dvfcloud.png';
import { isPlatformIncludes, EnvPlatform } from '@/configs/constant';

const isDvfCloud = isPlatformIncludes(EnvPlatform.dvfcloud);
const isUsEast = isPlatformIncludes(EnvPlatform.usEast);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-size: cover;
  background-image: url(${backgroundImage});
`;
const LangWrapper = styled.div`
  width: 100%;
  height: 40px;
  padding-right: 32px;
  line-height: 44px;
  text-align: right;
`;
const Content = styled.div`
  flex: 1;
  padding: 32px 0;
  @media (min-width: 768px) {
    ${isUsEast
      ? css`
          padding: 90px 0 24px 0;
        `
      : css`
          padding: 32px 0 24px 0;
        `}
  }
`;
const Top = styled.div`
  text-align: center;
`;
const Header = styled.div`
  height: 44px;
  a {
    text-decoration: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.img`
  height: ${isDvfCloud ? 36 : isUsEast ? 32 : 44}px;
  ${isUsEast
    ? css`
        height: 32px;
        width: 32px;
        margin-right: 8px;
      `
    : css`
        margin-right: 16px;
        vertical-align: top;
      `}
`;
const Title = styled.span`
  position: relative;
  ${isDvfCloud
    ? css`
        opacity: 0.85;
        font-family: PingFangSC-Medium;
        font-size: 32px;
        color: #000000;
      `
    : isUsEast
    ? css`
        font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-weight: 500;
        color: rgba(40, 126, 241, 0.85);
        font-size: 26px;
      `
    : css`
        top: 2px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-size: 33px;
        font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      `}
`;
const Banner = styled.div`
  ${isUsEast
    ? css`
        margin-top: 24px;
        margin-bottom: 32px;
        font-size: 26px;
        color: #000000;
      `
    : css`
        margin-top: 12px;
        margin-bottom: 40px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      `}
`;
const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

@injectIntl
@observer
class CommonContainer extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    children: PropTypes.element.isRequired,
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { children } = this.props;
    return (
      <Container>
        <LangWrapper>
          <SelectLang />
        </LangWrapper>
        <Content>
          <Top>
            <Header>
              {isDvfCloud ? <Logo src={dvfcloudImage} /> : <Logo src={logoImage} />}
              {isDvfCloud ? <Title>{formatMessage({ id: 'dvcloud' })}</Title> : <Title>Seal</Title>}
            </Header>
            {isDvfCloud ? (
              <Banner>{formatMessage({ id: 'dvcloud_slogan' })}</Banner>
            ) : isUsEast ? (
              <Banner>{formatMessage({ id: 'login.banner.eva' })}</Banner>
            ) : (
              <Banner>{formatMessage({ id: 'login.banner' })}</Banner>
            )}
          </Top>
          <MainWrapper>{children}</MainWrapper>
        </Content>
        <Footer />
      </Container>
    );
  }
}

export default CommonContainer;

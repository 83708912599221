import { addLocaleData } from 'react-intl';
import enData from 'react-intl/locale-data/en';
import zhData from 'react-intl/locale-data/zh';

import zhCN from './zh.json';
import zhHK from './zh-Hant-HK.json';
import enUS from './en.json';

addLocaleData([...zhData, ...enData]);

export default {
  zh: zhCN,
  'zh-Hant-HK': zhHK,
  en: enUS,
};

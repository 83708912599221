import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from '@/pages/Login';
import ExtractFiles from '@/pages/ExtractFiles';

@withRouter
@inject('appStore')
@observer
class App extends React.Component {
  static propTypes = {
    appStore: PropTypes.shape({
      redirect: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { redirect } = this.props.appStore;

    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/extract" component={ExtractFiles} />
        <Redirect to={redirect} />
      </Switch>
    );
  }
}

export default App;
